import React, { useState, useEffect, useRef, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { RadioButton } from "ui/RadioButton";
import Select, {
  SelectOptionType,
  SelectRef,
} from "app/component/select/Select";
import ModalContainer from "app/component/modal/ModalContainer";
import { useSelector, useDispatch } from "react-redux";
import {
  updateExpenseType,
  updateBusinessGoal,
  setBusinessGoal as setBusinessGoalAction,
  loaderLock,
  loaderUnlock,
} from "store/common/actions";
import {
  formatter,
  nameFormatter,
  NameFormats,
  getNextKey,
  toDate,
  returnDateWithoutUTC,
} from "app/utils";
import CityAutocomplete, {
  CityAutocompleteOptionType,
} from "../autocomplete/CityAutocomplete";
import { updateDetailedAO } from "store/report/actions";
import { IAO } from "infrastructure/interfaces";
import SelectExpense from "../select-expense/SelectExpense";
import { showErrors } from "store/exception/actions";
import Config, { ConfigTypes } from "services/ConfigService";
import _, { get } from "lodash";
import Validator from "../validator/Validator";
import { OCCUPATION_ROLE_TYPE } from "infrastructure/enum/user-role.enum";
import EmployeeAutocomplete, {
  EmployeeAutocompleteOptionType,
} from "../autocomplete/EmployeeAutocomplete";
import { RouteComponentProps, withRouter } from "react-router";
import Road, { RoadObj } from "./Road";
import If from "../util/If";
import CreateOrderModal from "./CreateOrderModal";
import moment from "moment";
import analitiks from "services/AnaliticsService";
import { isIE } from "react-device-detect";
import SvgIcon from "../svg-icon/SvgIcon";
import { ADVANCE_APPLICATION_PROPETIES } from "infrastructure/enum/object-properties.enum";
import CustomProperty from "../custom-property/CustomProperty";
import InputChoose, { InputChooseItem } from "../inputchoose/InputChoose";
import DatePickerCalendar from "../date-picker/DatePickerCalendar";
import {
  getAdvanceReportApplication,
  getCommonBusinessGoals,
  getCommonExpenseTypes,
  getCommonUserDetail,
} from "../../../store/selectors";
import {
  deleteExpensesById,
  getAssistancesById,
  getFieldPermissionsCreateAdvanceReportApplications,
  getFieldPermissionsUpdateAdvanceReportApplications,
  getOccupationsShortByEmployeeId,
  postAdvanceReportApplications,
  updateAdvanceReportApplicationsByIdWithParams,
  updatePerDiemCalculations,
  getPerDiemRecalculationBusinessGoals,
  availableCustomProperties,
} from "../../../services/ApiService";
import { Button } from "ui/Button";

interface CreateAOModalProps extends RouteComponentProps<any> {
  isOpen: boolean;
  onClose: (isSimpleClose?: boolean) => void;
  editId?: number;
  hasNext?: boolean;
}

export interface FilterCustomProperties {
  id: number;
  name: {
    en: string;
    ru: string;
  };
}

const CreateAOModal: React.FC<CreateAOModalProps> = (props) => {
  let { t, i18n } = useTranslation();

  const expenseTypes = useSelector(getCommonExpenseTypes);
  const businessGoalOptions = useSelector(getCommonBusinessGoals);
  const advanceReportDetail: IAO =
    useSelector(getAdvanceReportApplication) || ({} as IAO);
  const userDetail = useSelector(getCommonUserDetail);

  const dispatch = useDispatch();

  const [hideBusinessGoal, setHideBusinessGoal] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [multiRoadMode, setMultiRoadMode] = useState(false);

  const [expenseType, setExpenseType] = useState(3);

  const [expenseTypeCode, setExpenseTypeCode] = useState("General");
  const [description, setDescription] = useState("");
  const [businessGoal, setBusinessGoal] = useState({} as SelectOptionType);
  const [businessGoalOnlyOne, setBusinessGoalOnlyOne] = useState(false);
  const [expenseApplication, setExpenseApplication] = useState({} as any);
  const [withExpense, setWithExpense] = useState(true);
  const [assistance, setAssistance] = useState({ text: "", value: 0 });
  const [responsible, setResponsible] = useState({ text: "", value: 0 });
  const [filterCustomProperties, setFilterCustomProperties] = useState<
    FilterCustomProperties[]
  >([]);
  const [employeeAutocompleteReadOnly, setEmployeeAutocompleteReadOnly] =
    useState(false);
  const [isShowDailyRenew, setShowDailyRenew] = useState(false);
  const [assistanceOptions, setAssistantOptions] = useState(
    [] as SelectOptionType[]
  );
  const [responsibleOccupation, setResponsibleOccupation] = useState(
    {} as SelectOptionType
  );
  const [responsibleOccupationOptions, setResponsibleOccupationOptions] =
    useState([] as SelectOptionType[]);
  const [creatorOccupationOptions, setOccupationOptions] = useState(
    [] as SelectOptionType[]
  );

  const [selectedOccupation, setSelectedOccupation] = useState({} as any);
  const [assigneeDetail, setAssigneeDetail] = useState({
    userId: 0,
    occupationId: 0,
    companyId: 0,
  });
  const [editPermissions, setEditPermissions] = useState([] as string[]);
  const [createPermissions, setCreatePermissions] = useState([] as string[]);
  const [readPermissions, setReadPermissions] = useState([] as string[]);
  const [isTabIndexDisabled, setTabIndexDisabled] = useState(false);
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null as any);
  const [isOpenToCalendar, setOpenToCalendar] = useState(false);
  const [isOpenBackCalendar, setOpenBackCalendar] = useState(false);
  const [options, setOptions] = useState([] as InputChooseItem[]);
  const [filterParams, setFilterParams] = useState({} as any);
  const [roads, setRoads] = useState([] as RoadObj[]);
  const [isBusinessGoalChange, setBusinessGoalChange] = useState(false);
  const [recalculationBusinessGoal, setRecalculationBusinessGoals] = useState(
    [] as number[]
  );
  const [isSelectOccupation, setSelectOccupation] = useState(false);

  const descriptionRef = useRef({} as HTMLInputElement);
  const businessGoalRef = useRef<SelectRef>({} as SelectRef);
  const assistanceRef = useRef<SelectRef>({} as SelectRef);
  const creatorOccupationRef = useRef<SelectRef>({} as SelectRef);
  const customPropertyRef = useRef({} as any);
  const dataRef = useRef({} as any);

  const expenseValidator = useRef({} as any);
  const businessGoalValidator = useRef({} as any);
  const responsibleValidatorRef = useRef({} as any);
  const assistanceValidatorRef = useRef({} as any);
  const creatorOccupationValidatorRef = useRef({} as any);
  const responsibleOccupationRef = useRef({} as any);

  const [employee, setEmployee] = useState(
    {} as EmployeeAutocompleteOptionType
  );
  const employeeAutocompleteRef = useRef({} as any);
  const cityAutocompleteRef = useRef({} as any);

  const arrayExpenseTypes = useRef<Array<any>>();

  useEffect(() => {
    if (editMode) {
      const businessTargetIdDefault: number = advanceReportDetail
        ? advanceReportDetail.businessTarget.id
        : -1;

      if (recalculationBusinessGoal.includes(businessTargetIdDefault)) {
        recalculationBusinessGoal.includes(+businessGoal.value)
          ? setBusinessGoalChange(false)
          : setBusinessGoalChange(true);
      } else {
        !recalculationBusinessGoal.includes(+businessGoal.value)
          ? setBusinessGoalChange(false)
          : setBusinessGoalChange(true);
      }
    }
  }, [
    advanceReportDetail,
    businessGoal,
    editMode,
    isBusinessGoalChange,
    recalculationBusinessGoal,
  ]);

  useEffect(() => {
    if (props.isOpen) {
      setSelectedOccupation({} as any);
      setWithExpense(true);
      setAssistantOptions([]);
      setResponsibleOccupationOptions([]);
      setStartDate(null);
      setEndDate(null);
      setOptions([]);
      setEmployee({} as EmployeeAutocompleteOptionType);
      if (props.editId) {
        initEditMode(props.editId);
        if (advanceReportDetail && advanceReportDetail.properties) {
          setReadPermissions(advanceReportDetail.properties);
        }
      } else {
        if (userDetail.occupations.length <= 1) {
          initClearMode();
          setAssigneeDetail({
            userId: userDetail.id,
            occupationId: userDetail.occupations[0].occupationId,
            companyId: userDetail.occupations[0].company.id,
          });
          dispatch(updateExpenseType(userDetail.occupations[0].company.id));
        }
      }
      initOccupationOptions(userDetail.occupations);
    } else {
      setFilterParams({
        Status: ["3", "6"],
        EmployeeId: userDetail.id,
        CreatedAdvanceReportApplication: false,
      });
    }
  }, [props.isOpen]);

  useEffect(() => {
    props.isOpen && checkBusinessGoalVisibillity();
  }, [selectedOccupation, props.isOpen]);

  useEffect(() => {
    if (props.isOpen && editMode) {
      setInitialValues();
    }
  }, [advanceReportDetail]);

  const getAvailableCustomProperties = async () => {
    if (businessGoal && !!businessGoal.value) {
      const data = await availableCustomProperties(Number(businessGoal.value));
      setFilterCustomProperties(data.data.customProperties);
      if (
        customPropertyRef &&
        customPropertyRef.current &&
        customPropertyRef.current.rebuild &&
        !hideBusinessGoal
      ) {
        customPropertyRef.current.rebuild();
      }
    }
  };

  useEffect(() => {
    getAvailableCustomProperties();
  }, [businessGoal, hideBusinessGoal]);

  const checkBusinessGoalVisibillity = () => {
    const id =
      selectedOccupation && selectedOccupation.company
        ? selectedOccupation.company.id
        : advanceReportDetail && advanceReportDetail.company
          ? advanceReportDetail.company.id
          : 0;
    const isVisible = Config.getConfigToCompany(
      ConfigTypes.HIDE_BUSINESS_TARGET,
      id
    );
    setHideBusinessGoal(isVisible);
  };

  useEffect(() => {
    setRoads([
      {
        id: getNextKey("road"),
        departureFromCityId: 0,
        departureFromCityName: "",
        arrivalToCityId: 0,
        arrivalToCityName: "",
        startOn: null as any,
        endOn: null as any,
        validate: false,
        initRoad: true,
      },
    ] as RoadObj[]);
    if (!withExpense && userDetail.occupations.length <= 1 && !editMode) {
      creatorOccupationtChooseHandler({
        text: "",
        value: userDetail.occupations[0].occupationId,
      });
    }
    dispatch(updateExpenseType(userDetail.occupations[0].company.id));
  }, [withExpense]);

  useEffect(() => {
    getCreatePermissions();
  }, []);

  useEffect(() => {
    if (_.isEqual(expenseTypes, arrayExpenseTypes.current)) {
      return;
    }
    arrayExpenseTypes.current = expenseTypes;
    if (expenseTypes && expenseTypes.length && !props.editId) {
      const currentExpenseType =
        expenseTypes.find((type) => type.id === expenseType) ||
        expenseTypes.find((type) => type.code === "General") ||
        expenseTypes[0];
      const { id, code } = currentExpenseType;
      setExpenseType(id);
      setExpenseTypeCode(code);
      dispatch(updateBusinessGoal(id));
    } else {
      dispatch(setBusinessGoalAction([]));
    }
  }, [expenseTypes]);

  useEffect(() => {
    if (!businessGoalOptions) {
      return;
    }

    let businessGoals = businessGoalOptions.map((businessGoalOption) => {
      return {
        value: businessGoalOption.id,
        text: businessGoalOption.name[i18n.language as "ru"],
      };
    });

    if (businessGoals.length == 1) {
      setBusinessGoal(businessGoals[0]);
      setBusinessGoalOnlyOne(true);
    } else {
      if (!props.editId) {
        setBusinessGoal({} as SelectOptionType);
        setBusinessGoalOnlyOne(true);
      }
    }
  }, [businessGoalOptions]);

  const initClearMode = async () => {
    await getCreatePermissions();
    setEditMode(false);
    setDescription("");
    setExpenseApplication({} as any);
    setRoads([
      {
        id: getNextKey("road"),
        departureFromCityId: 0,
        departureFromCityName: "",
        arrivalToCityId: 0,
        arrivalToCityName: "",
        startOn: null as any,
        endOn: null as any,
        validate: false,
        initRoad: true,
      },
    ] as RoadObj[]);
  };

  const initEditMode = async (id: number) => {
    setEditMode(true);

    let companyId = advanceReportDetail ? advanceReportDetail.company.id : 0;
    await getRecalculationBusinessGoals(companyId, "AdvanceReportApplication");

    await getEditPermissions();
    await dispatch(updateDetailedAO(id));
    await dispatch(updateExpenseType(advanceReportDetail.company.id));
    await dispatch(updateBusinessGoal(advanceReportDetail.applicationType.id));
    if (advanceReportDetail.applicationType.code === "Travel") {
      let mapEditRoads = ([] as RoadObj[])
        .concat({
          id: getNextKey("road"),
          departureFromCityId: advanceReportDetail
            ? advanceReportDetail.departureFromCityId.id
            : -1,
          departureFromCityName: advanceReportDetail
            ? advanceReportDetail.departureFromCityId.name[
                i18n.language as "ru"
              ]
            : "",
          arrivalToCityId: advanceReportDetail
            ? advanceReportDetail.businessTripDestinations.length > 0
              ? advanceReportDetail.businessTripDestinations[0].city.id
              : advanceReportDetail.arrivalToCityId.id
            : -1,
          arrivalToCityName: advanceReportDetail
            ? advanceReportDetail.businessTripDestinations.length > 0
              ? advanceReportDetail.businessTripDestinations[0].city.name[
                  i18n.language as "ru"
                ]
              : advanceReportDetail.arrivalToCityId.name[i18n.language as "ru"]
            : "",
          startOn: advanceReportDetail
            ? toDate(advanceReportDetail.startOn)
            : "",
          endOn: advanceReportDetail
            ? advanceReportDetail.businessTripDestinations.length > 0
              ? toDate(advanceReportDetail.businessTripDestinations[0].leaveOn)
              : toDate(advanceReportDetail.endOn)
            : "",
          validate: false,
        })
        .concat(
          advanceReportDetail.businessTripDestinations
            .filter((road, index) => {
              return index != 0;
            })
            .map((road) => {
              return {
                id: getNextKey("road"),
                departureFromCityId: -1,
                departureFromCityName: "",
                arrivalToCityId: road.city.id,
                arrivalToCityName: road.city.name[i18n.language as "ru"],
                startOn: toDate(road.arrivalOn),
                endOn: toDate(road.leaveOn),
                validate: false,
              };
            })
        );

      setMultiRoadMode(mapEditRoads.length > 1);
      setRoads(mapEditRoads);
    }
    setEmployeeAutocompleteReadOnly(true);
    setAssistance({
      text: nameFormatter(
        advanceReportDetail.assigneeEmployee.name,
        NameFormats.FULL,
        i18n.language
      ),
      value: advanceReportDetail.assigneeEmployee.id,
    });
    await customPropertyRef.current.build();
  };

  const getEditPermissions = async () => {
    let result = await getFieldPermissionsUpdateAdvanceReportApplications(
      advanceReportDetail ? advanceReportDetail.id : 0
    );
    if (result.headers.success) {
      let permissions = result.data;
      setEditPermissions(permissions);
    }
  };

  const getRecalculationBusinessGoals = async (
    companyId: number,
    objectTypeId: string
  ) => {
    let result = await getPerDiemRecalculationBusinessGoals(companyId);
    if (result.headers.success) {
      let recalculationBusinessGoals = result.data
        .filter(
          (item: { objectTypeId: string }) => item.objectTypeId === objectTypeId
        )
        .map((item: { valueInt: number }) => item.valueInt);
      setRecalculationBusinessGoals(recalculationBusinessGoals);
    }
  };

  const getCreatePermissions = async () => {
    let result = await getFieldPermissionsCreateAdvanceReportApplications();
    if (result.headers.success) {
      let permissions = result.data;
      setCreatePermissions(permissions);
    }
  };

  useEffect(() => {}, [createPermissions, editPermissions]);

  const generateClassName = (properties: string): string => {
    let classes = [];
    if (editMode) {
      !readPermissions.includes(properties) && classes.push("d-none");
      !editPermissions.includes(properties) && classes.push("disabled");
      return classes.join(" ");
    }
    !createPermissions.includes(properties) && classes.push("disabled");
    return classes.join(" ");
  };

  const initOccupationOptions = (data: any) => {
    let newOccupationOptions = [] as SelectOptionType[];
    let occupationOptions = data.map((item: any) => {
      return {
        occupationId: item.occupationId ? item.occupationId : item.id,
        company: item.company.name[i18n.language as "ru" | "en"], //get(item.company.name., `company.name.${}`)
        code: item.code,
        name: item.name[i18n.language as "ru" | "en"],
      };
    });
    occupationOptions.forEach((item: any) => {
      newOccupationOptions.push({
        value: item.occupationId,
        text: `${item.company} - ${item.name}`,
      });
    });
    setOccupationOptions(newOccupationOptions);
  };

  const isAssistantManagerOccupation = (): boolean => {
    if (!selectedOccupation.occupationId) {
      return false;
    }
    let occupations = selectedOccupation.roles.map((role: any) => role.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.ASSISTANT_MANAGER);
  };

  const setInitialValues = async () => {
    if (advanceReportDetail) {
      setWithExpense(false); //changed(!!advanceReportDetail.expenseApplication.id);
      if (!!advanceReportDetail.expenseApplication.id) {
        setExpenseApplication({
          value: advanceReportDetail.expenseApplication.id,
          text: `№${advanceReportDetail.expenseApplication.id} (${
            advanceReportDetail.applicationType.name[
              i18n.language as "en" | "ru"
            ]
          }) от ${formatter(
            "DD.MM.YYYY",
            i18n.language,
            advanceReportDetail.createdAt
          )}, ${nameFormatter(
            advanceReportDetail.createdByEmployee.name,
            NameFormats.NAME_WITH_LASTNAME,
            i18n.language
          )}`,
        });
      }
      setExpenseType(advanceReportDetail.applicationType.id);
      setExpenseTypeCode(advanceReportDetail.applicationType.code);
      dispatch(updateBusinessGoal(advanceReportDetail.applicationType.id));
      setBusinessGoal({
        value: advanceReportDetail.businessTarget.id,
        text: advanceReportDetail.businessTarget.name
          ? advanceReportDetail.businessTarget.name[
              i18n.language as "en" | "ru"
            ]
          : "",
      });
      setDescription(advanceReportDetail.description);

      if (advanceReportDetail.applicationType.code === "Travel") {
        let mapEditRoads = ([] as RoadObj[])
          .concat({
            id: getNextKey("road"),
            departureFromCityId: advanceReportDetail
              ? advanceReportDetail.departureFromCityId.id
              : -1,
            departureFromCityName: advanceReportDetail
              ? advanceReportDetail.departureFromCityId.name[
                  i18n.language as "ru"
                ]
              : "",
            arrivalToCityId: advanceReportDetail
              ? advanceReportDetail.businessTripDestinations.length > 0
                ? advanceReportDetail.businessTripDestinations[0].city.id
                : advanceReportDetail.arrivalToCityId.id
              : -1,
            arrivalToCityName: advanceReportDetail
              ? advanceReportDetail.businessTripDestinations.length > 0
                ? advanceReportDetail.businessTripDestinations[0].city.name[
                    i18n.language as "ru"
                  ]
                : advanceReportDetail.arrivalToCityId.name[
                    i18n.language as "ru"
                  ]
              : "",
            startOn: advanceReportDetail
              ? returnDateWithoutUTC(advanceReportDetail.startOn)
              : "",
            endOn: advanceReportDetail
              ? advanceReportDetail.businessTripDestinations.length > 0
                ? returnDateWithoutUTC(
                    advanceReportDetail.businessTripDestinations[0].leaveOn
                  )
                : returnDateWithoutUTC(advanceReportDetail.endOn)
              : "",
            validate: false,
          })
          .concat(
            advanceReportDetail.businessTripDestinations
              .filter((road, index) => {
                return index != 0;
              })
              .map((road) => {
                return {
                  id: getNextKey("road"),
                  departureFromCityId: -1,
                  departureFromCityName: "",
                  arrivalToCityId: road.city.id,
                  arrivalToCityName: road.city.name[i18n.language as "ru"],
                  startOn: returnDateWithoutUTC(road.arrivalOn),
                  endOn: returnDateWithoutUTC(road.leaveOn),
                  validate: false,
                };
              })
          );

        setMultiRoadMode(mapEditRoads.length > 1);
        setRoads(mapEditRoads);
      }
    }
  };

  const changeDescription = () => {
    setDescription(descriptionRef.current.value);
  };

  const isCoordinatorOccupation = (): boolean => {
    if (!selectedOccupation.occupationId) {
      return false;
    }
    let occupations = selectedOccupation.roles.map((role: any) => role.code);
    return occupations.includes(OCCUPATION_ROLE_TYPE.COORDINATOR);
  };

  const initAssistantOptions = async (occupationId: number) => {
    dispatch(loaderLock());
    let newAssistantOptions = [] as SelectOptionType[];

    let selectedOccupation = userDetail.occupations.find(
      (item) => item.occupationId == occupationId
    );

    let selectedOccupationName = selectedOccupation
      ? selectedOccupation.name[i18n.language as "ru"]
      : "";
    let selectedOccupationCompany = selectedOccupation
      ? selectedOccupation.company.name[i18n.language as "ru"]
      : "";

    setAssigneeDetail({
      userId: userDetail.id,
      occupationId: selectedOccupation ? selectedOccupation.occupationId : 0,
      companyId: selectedOccupation ? selectedOccupation.company.id : 0,
    });

    let response = await getAssistancesById(occupationId);
    if (response.headers.success) {
      let assistanceList = response.data.value.assistances.map((item: any) => {
        return {
          name: {
            ru: get(item, "employeeName.ru", ""),
            en: get(item, "employeeName.en", ""),
          },
          employeeOccupationName: {
            ru: get(item, "employeeOccupationName.ru", ""),
            en: get(item, "employeeOccupationName.en", ""),
          },
          company: {
            id: get(item, "company.id", 0),
            name: {
              ru: get(item, "company.name.ru", ""),
              en: get(item, "company.name.en", ""),
            },
          },
          employeeOccupationId: get(item, "employeeOccupationId", ""),
          employeeId: get(item, "employeeId", 0),
        };
      });
      if (assistanceList.length > 0) {
        newAssistantOptions.push({
          value: occupationId,
          text: `${
            userDetail.name[i18n.language as "ru"]
          } - ${selectedOccupationCompany} - ${selectedOccupationName}`,
          detail: {
            name: get(selectedOccupation, "employee.name", { ru: "", en: "" }),
            employeeOccupationName: get(selectedOccupation, "name", {
              ru: "",
              en: "",
            }),
            company: {
              id: get(selectedOccupation, "company.id", 0),
              name: get(selectedOccupation, "company.name", { ru: "", en: "" }),
            },
            employeeOccupationId: get(selectedOccupation, "occupationId", 0),
            employeeId: userDetail.id,
          },
        } as any);
        assistanceList.forEach((item: any) => {
          newAssistantOptions.push({
            value: item.employeeId,
            text: `${item.name[i18n.language as "ru"]} - ${
              item.company.name[i18n.language as "ru"]
            } - ${item.employeeOccupationName[i18n.language as "ru"]}`,
            detail: item,
          } as any);
        });
      }
    } else {
      dispatch(
        showErrors({
          code: "del_substituation",
          message: "Не удалось загрузить сотрудников",
        })
      );
    }
    setAssistantOptions(newAssistantOptions);
    if (
      newAssistantOptions.length > 0 &&
      !isAssistantManagerOccupation() &&
      !isCoordinatorOccupation()
    ) {
      setAssistance({
        value: occupationId,
        text: `${
          userDetail.name[i18n.language as "ru"]
        } - ${selectedOccupationCompany} - ${selectedOccupationName}`,
      });
    }
    dispatch(loaderUnlock());
  };

  const creatorOccupationtChooseHandler = async (option: any) => {
    setSelectOccupation(true);
    let selectedOccupation = userDetail.occupations.find(
      (item) => item.occupationId == option.value
    );
    setSelectedOccupation(selectedOccupation);
    setResponsibleOccupationOptions([]);

    let companyId = selectedOccupation ? selectedOccupation.company.id : 0;
    let occupationId = selectedOccupation ? selectedOccupation.occupationId : 0;

    setAssigneeDetail({
      userId: userDetail.id,
      occupationId: occupationId,
      companyId: companyId,
    });
    await dispatch(updateExpenseType(companyId));

    customPropertyRef &&
      customPropertyRef.current &&
      customPropertyRef.current.build();

    // initClearMode();
    initAssistantOptions(occupationId);
  };

  const assistanceChooseHandler = async (option: any) => {
    setAssistance(option);
    setAssigneeDetail({
      userId: option.detail.employeeId,
      occupationId: option.detail.employeeOccupationId,
      companyId: option.detail.company.id,
    });

    await dispatch(updateExpenseType(option.detail.company.id));
    // initClearMode();
    customPropertyRef &&
      customPropertyRef.current &&
      customPropertyRef.current.build();
  };

  const responsibleChooseHandler = async (
    option: EmployeeAutocompleteOptionType
  ) => {
    setAssistance(option);

    if (option && option.value) {
      dispatch(loaderLock);
      let result = await getOccupationsShortByEmployeeId(
        option.value,
        assigneeDetail.companyId
      );

      if (result.headers.success) {
        let newOption = result.data.map((item: any) => {
          return {
            text: `${get(item, `company.name.${i18n.language}`, "")} - ${get(
              item,
              `name.${i18n.language}`,
              ""
            )}`,
            value: get(item, "id", 0),
            detail: {
              companyId: get(item, "company.id", 0),
            },
          };
        });

        setResponsibleOccupationOptions(newOption);
        setResponsibleOccupation(newOption[0]);
        setAssigneeDetail({
          userId: option.value,
          occupationId: newOption[0].value,
          companyId: newOption[0].detail.companyId,
        });
        await dispatch(updateExpenseType(newOption[0].detail.companyId));
        // initClearMode();
        customPropertyRef &&
          customPropertyRef.current &&
          customPropertyRef.current.build();
      } else {
        dispatch(
          showErrors({
            code: "expense_applications_error",
            message: "Не удалось получить список назначений",
          })
        );
      }
      dispatch(loaderUnlock);
    }
  };

  const responsibleOccupationChooseHandler = async (option: any) => {
    setResponsibleOccupation(option);
    setAssigneeDetail({
      userId: assigneeDetail.userId,
      occupationId: option.value,
      companyId: option.detail.companyId,
    });
    await dispatch(updateExpenseType(option.detail.companyId));
    // initClearMode();
    customPropertyRef &&
      customPropertyRef.current &&
      customPropertyRef.current.build();
  };

  const typeRadioClickHandler = (id: number) => {
    setExpenseType(+id);
    expenseTypes.forEach((expense) => {
      if (expense.id === id) {
        setExpenseTypeCode(expense.code);
        dispatch(updateBusinessGoal(id));
        businessGoalRef.current.clear();
      }
    });
    if (customPropertyRef && customPropertyRef.current) {
      customPropertyRef.current.rebuild();
    }
  };

  const businessGoalChooseHandler = (option: SelectOptionType) => {
    setBusinessGoal(option);
  };

  const changeRoads = (roads: RoadObj[]) => {
    setRoads(roads);
  };

  const closeHandler = () => {
    props.onClose(true);
    setSelectOccupation(false);
  };

  const validate = (): boolean => {
    let isValid = true;
    let validateList = withExpense
      ? [expenseValidator.current.validate()]
      : [businessGoalValidator.current.validate()];
    if (!withExpense && creatorOccupationOptions.length > 1 && !editMode) {
      validateList.push(
        ...validateList,
        creatorOccupationValidatorRef.current.validate()
      );
    }
    if (!withExpense && isCoordinatorOccupation()) {
      validateList = [
        ...validateList,
        responsibleValidatorRef.current.validate(),
      ];
    }
    if (
      !withExpense &&
      assistanceOptions.length > 0 &&
      !isAssistantManagerOccupation() &&
      !isCoordinatorOccupation()
    ) {
      validateList = [
        ...validateList,
        assistanceValidatorRef.current.validate(),
      ];
    }
    if (!withExpense && expenseTypeCode === "Travel") {
      setRoadValidation(true);
      roads.forEach((road, index) => {
        if (!validateRoad(road, index)) {
          isValid = false;
        }
      });
    }
    validateList.forEach((item) => {
      if (!item) {
        isValid = false;
        return;
      }
    });
    if (!withExpense && !customPropertyRef.current.validate()) {
      isValid = false;
    }
    return isValid;
  };

  const setRoadValidation = (validate: boolean) => {
    // if (roads[0].initRoad) {
    //   return
    // }
    let newRoads: RoadObj[] = roads.map((road) => {
      road.validate = validate;
      return road;
    });
    setRoads(newRoads);
  };

  const validateRoad = (road: RoadObj, index: number): boolean => {
    if (!road.validate) {
      return true;
    }
    if (road.departureFromCityId === 0 && index == 0) {
      return false;
    }
    if (road.arrivalToCityId === 0) {
      return false;
    }
    if (!road.startOn) {
      return false;
    }
    if (!road.endOn) {
      return false;
    }
    if (!multiRoadMode && road.startOn.valueOf() > road.endOn.valueOf()) {
      return false;
    }
    return true;
  };

  const saveHandler = () => {
    if (!validate()) {
      return;
    }
    if (editMode) {
      edit();
    } else {
      create();
    }
    setRoadValidation(false);

    if (
      isBusinessGoalChange &&
      advanceReportDetail.expenses.filter(
        (expense) => expense.expenseType.groupCode === "Perdiem"
      ).length > 0
    ) {
      setShowDailyRenew(isBusinessGoalChange);
      dailyRenew();
    }
  };

  const editSubmit = async (data: any) => {
    dispatch(loaderLock());
    let perDiemCalculationType = Config.getConfigToCompany(
      ConfigTypes.PERDIEM_CALCULATION_TYPE,
      assigneeDetail.companyId
    );

    try {
      let needRenew = false;
      let dateDiffer = (dateA: any, dateB: any) => {
        return (
          +moment(dateA).format("YYYYMMDD") == +moment(dateB).format("YYYYMMDD")
        );
      };

      if (
        perDiemCalculationType != 0 &&
        advanceReportDetail.expenses.filter(
          (item) => item.expenseType.expenseGroup.code == "Perdiem"
        ).length > 0
      ) {
        if (
          data.businessTripOption &&
          !dateDiffer(
            data.businessTripOption.startOn,
            advanceReportDetail.startOn
          )
        ) {
          needRenew = true;
        }
        if (
          data.businessTripOption &&
          !dateDiffer(data.businessTripOption.endOn, advanceReportDetail.endOn)
        ) {
          needRenew = true;
        }

        if (
          data.businessTripOption &&
          data.businessTripOption.departureFromCityId !=
            advanceReportDetail.departureFromCityId.id
        ) {
          needRenew = true;
        }
        if (
          data.businessTripOption &&
          data.businessTripOption.arrivalToCityId !=
            advanceReportDetail.arrivalToCityId.id
        ) {
          needRenew = true;
        }

        let oldTrip = advanceReportDetail.businessTripDestinations;
        let newTrip = data.businessTripOption
          ? data.businessTripOption.businessTripDestination
            ? data.businessTripOption.businessTripDestination
            : []
          : [];
        if (oldTrip.length == newTrip.length) {
          oldTrip.sort((a, b) => a.ordinal - b.ordinal);
          newTrip.sort((a: any, b: any) => a.ordinal - b.ordinal);

          for (let index = 0; index < oldTrip.length; index++) {
            const oldElement = oldTrip[index];
            const newElement = newTrip[index];
            if (oldElement.city.id != newElement.cityId) {
              needRenew = true;
            }
            if (!dateDiffer(oldElement.leaveOn, newElement.leaveOn)) {
              needRenew = true;
            }
            if (!dateDiffer(oldElement.arrivalOn, newElement.arrivalOn)) {
              needRenew = true;
            }
          }
        } else {
          needRenew = true;
        }
      }
      await updateAdvanceReportApplicationsByIdWithParams(props.editId, data);
      await customPropertyRef.current.edit();
      props.editId && (await dispatch(updateDetailedAO(props.editId)));

      if (needRenew) {
        dailyRenew();
      }
      analitiks("EditingAO", {
        aoId: props.editId,
        occupationId: get(
          userDetail.occupations.find(
            (occupation) => occupation.company.id === data.companyId
          ) || userDetail.occupations[0],
          "occupationId"
        ),
        companyId: data.companyId,
        employeeId: userDetail.id,
      });
      props.onClose(false);
    } catch (e) {
      dispatch(
        showErrors({
          code: "edit_report",
          message: "Не удалось отредактировать отчет",
        })
      );
    }
    dispatch(loaderUnlock());
  };

  useEffect(() => {
    dataRef.current.businessTargetId = +businessGoal.value;
  }, [businessGoal]);

  useEffect(() => {
    dataRef.current.journeyNumber =
      advanceReportDetail.journey && advanceReportDetail.journey.journeyNumber;
    dataRef.current.businessTripNumber =
      advanceReportDetail.journey &&
      advanceReportDetail.journey.businessTripNumber;
    dataRef.current.advanceCashAmount =
      advanceReportDetail && advanceReportDetail.advanceCashAmount;
    dataRef.current.totalAmount =
      advanceReportDetail && advanceReportDetail.totalAmount;
    dataRef.current.companyId =
      advanceReportDetail &&
      advanceReportDetail.company &&
      advanceReportDetail.company.id;
  }, [advanceReportDetail]);

  useEffect(() => {
    dataRef.current.description = description;
  }, [description]);

  useEffect(() => {
    dataRef.current.applicationTypeId = expenseType;
  }, [expenseType]);

  useEffect(() => {
    dataRef.current.assigneeEmployeeId =
      assigneeDetail && assigneeDetail.userId;
  }, [assigneeDetail]);

  useEffect(() => {
    if (expenseTypeCode === "Travel") {
      let dayNumber = (date: Date) => {
        return +moment(date).format("YYYYMMDD");
      };
      let applicationTravelDates =
        advanceReportDetail &&
        advanceReportDetail.applicationTravelDates &&
        advanceReportDetail.applicationTravelDates.filter((item) => {
          if (dayNumber(roads[0].startOn) > dayNumber(item.startDate)) {
            return false;
          }
          return (
            dayNumber(roads[roads.length - 1].endOn) >= dayNumber(item.endDate)
          );
        });

      dataRef.current.businessTripOption = {
        departureFromCityId: roads[0] ? roads[0].departureFromCityId : null,
        startOn:
          roads[0] && roads[0].startOn
            ? returnDateWithoutUTC(roads[0].startOn)
            : null,
        arrivalToCityId: roads[roads.length - 1]
          ? roads[roads.length - 1].arrivalToCityId
          : null,
        endOn:
          roads[roads.length - 1] && roads[roads.length - 1].endOn
            ? returnDateWithoutUTC(roads[roads.length - 1].endOn)
            : null,
        personalDaysNumber:
          advanceReportDetail && advanceReportDetail.personalDaysNumber,
        applicationTravelDates: applicationTravelDates,
      };
      if (roads.length > 0) {
        dataRef.current.businessTripOption.businessTripDestination = roads.map(
          (road, index) => {
            return {
              cityId: road ? road.arrivalToCityId : null,
              arrivalOn:
                road && road.startOn
                  ? returnDateWithoutUTC(road.startOn)
                  : null,
              leaveOn:
                road && road.endOn ? returnDateWithoutUTC(road.endOn) : null,
              ordinal: index + 1,
            };
          }
        );
      }
    }
    if (expenseTypeCode !== "Travel") {
      delete dataRef.current.businessTripOption;
    }
  }, [roads, expenseTypeCode]);

  const edit = () => {
    if (editMode && withExpense) {
      props.onClose(true);
      return;
    }
    editSubmit(dataRef.current);
  };

  const saveSubmit = async (data: any) => {
    dispatch(loaderLock());
    let response = await postAdvanceReportApplications(
      withExpense ? expenseApplication.value.id : "",
      !withExpense ? data : null
    );
    if (response.headers.success) {
      if (!withExpense) {
        await customPropertyRef.current.saveById(
          response.data.id,
          response.data.company.id
        );
      }
      analitiks("SaveFormCreateAO", {
        aoId: response.data.id,
        type: response.data.applicationType.id,
        employeeId: userDetail.id,
        occupationId: get(
          userDetail.occupations.find(
            (occupation) => occupation.company.id === response.data.company.id
          ) || userDetail.occupations[0],
          "occupationId"
        ),
        companyId: response.data.company.id,
      });
      props.history.push(
        `/AdvanceReportApplication/Detail/${response.data.id}`
      );
      props.onClose(false);
    } else {
      switch (response.status) {
        case 400:
          dispatch(
            showErrors({
              code: "save_advance_report",
              message: JSON.stringify(response.data),
            })
          );
          break;
        default:
          dispatch(
            showErrors({
              code: "save_advance_report",
              message: "Не удалось создать авансовый отчет",
            })
          );
          break;
      }
    }
    dispatch(loaderUnlock());
  };

  const create = () => {
    saveSubmit({
      ...dataRef.current,
      occupationId: +assigneeDetail.occupationId,
    });
  };

  const advanceReportTypeChooseHandler = (id: number) => {
    setSelectOccupation(false);
    if (id === 1) {
      setWithExpense(true);
    } else {
      setWithExpense(false);
    }
  };

  const expenseApplicationOnChooseHandler = (value: any) => {
    setExpenseApplication(value);
  };

  const changeRoadMode = () => {
    let newRoads = [] as RoadObj[];
    newRoads.push({
      id: getNextKey("road"),
      departureFromCityId: roads[0].departureFromCityId,
      departureFromCityName: roads[0].departureFromCityName,
      arrivalToCityId: roads[0].arrivalToCityId,
      arrivalToCityName: roads[0].arrivalToCityName,
      startOn: roads[0].startOn,
      endOn: roads[0].endOn,
      validate: roads[0].validate,
    });
    if (!multiRoadMode) {
      newRoads.push({
        id: getNextKey("road"),
        departureFromCityId: 0,
        departureFromCityName: "",
        arrivalToCityId: 0,
        arrivalToCityName: "",
        startOn: null as any,
        endOn: null as any,
        validate: false,
      });
    }
    setRoads(newRoads);
    setMultiRoadMode(!multiRoadMode);
  };

  const dailyRenew = async () => {
    dispatch(loaderLock());
    let a = advanceReportDetail
      ? advanceReportDetail.expenses
          .filter((item: any) => {
            return item.expenseType.expenseGroup.code == "Perdiem";
          })
          .map((item: any) => item.id)
      : [];

    for (let index = 0; index < a.length; index++) {
      const element = a[index];
      await deleteExpensesById(element);
    }

    let data = {
      applicationRef: {
        id: advanceReportDetail ? advanceReportDetail.id : 0,
        logicalName: "AdvanceReportApplication",
        name: {
          ru: "AdvanceReportApplication",
          en: "AdvanceReportApplication",
        },
      },
      calculations: [],
    };
    await updatePerDiemCalculations(data);
    props.editId && (await dispatch(updateDetailedAO(props.editId)));
    dispatch(loaderUnlock());
    openDailyRenew();
  };

  const openDailyRenew = () => {
    setShowDailyRenew(true);
  };

  const closeDailyRenew = () => {
    setShowDailyRenew(false);
  };

  const employeeChooseHandler = (option: EmployeeAutocompleteOptionType) => {
    setEmployee(option);
  };

  const onSelectedToDate = (value: any) => {
    setStartDate(value.startDate);
    setOpenToCalendar(false);
  };

  const onClickToCalendar = () => {
    setOpenToCalendar(!isOpenToCalendar);
  };

  const onClickBackCalendar = () => {
    setOpenBackCalendar(!isOpenBackCalendar);
  };

  const onSelectedBackDate = (value: any) => {
    setEndDate(value.startDate);
    setOpenBackCalendar(false);
  };

  const onCityChooseHandler = (option: CityAutocompleteOptionType) => {
    cityAutocompleteRef.current.clear();
    let cityOptions: InputChooseItem[] = [...options];
    let index = cityOptions.findIndex((city) => city.value === option.value);
    if (index === -1) {
      cityOptions.push(option);
      setOptions(cityOptions);
    }
  };

  const onCityDeleteHandler = (option: InputChooseItem) => {
    let cityOptions: InputChooseItem[] = [...options];
    cityOptions.splice(
      cityOptions.findIndex((city) => city.value === option.value),
      1
    );
    setOptions(cityOptions);
  };

  const searchZNRHandler = () => {
    setFilterParams({
      Status: ["3", "6"],
      EmployeeId: employee.value,
      StartOn: startDate || null,
      EndOn: endDate || null,
      CityId: [...options.map((option) => option.value)],
      CreatedAdvanceReportApplication: false,
    });
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return (
    <Fragment>
      <ModalContainer
        onEnter={props.hasNext ? saveHandler : undefined}
        isOpen={props.isOpen}
        destroy={true}
        highestModal={isHighestModal}
        overlayClick={closeHandler}
      >
        <div className="box-modal">
          <div className="box-modal_close" onClick={closeHandler}></div>
          <div className="box-modal-title">
            {editMode
              ? t("modals.create_advance_report_modal.edit_application")
              : t("modals.create_advance_report_modal.create_application")}
          </div>
          <div className="box-modal-content">
            <div className="box-modal-form">
              <div className="box-modal-form-block">
                <div className="box-modal-checkbox-wrap m-b-15">
                  <RadioButton
                    checked={
                      editMode
                        ? !!advanceReportDetail.expenseApplication.id
                        : withExpense
                    }
                    label={t(
                      "modals.create_advance_report_modal.based_on_application_for_expense"
                    )}
                    onClick={
                      !editMode
                        ? () => {
                            advanceReportTypeChooseHandler(1);
                          }
                        : () => {}
                    }
                    id={1}
                    name={"radoii"}
                    key={1}
                    disabled={editMode}
                  />
                  <RadioButton
                    checked={
                      editMode
                        ? !advanceReportDetail.expenseApplication.id
                        : !withExpense
                    }
                    label={t(
                      "modals.create_advance_report_modal.no_application_for_expense"
                    )}
                    onClick={
                      !editMode
                        ? () => {
                            advanceReportTypeChooseHandler(2);
                          }
                        : () => {}
                    }
                    id={2}
                    name={"radoii"}
                    key={2}
                    disabled={editMode}
                  />
                </div>
                <If condition={!editMode && !withExpense}>
                  {creatorOccupationOptions.length > 1 ? (
                    <Validator
                      type={"select"}
                      ref={creatorOccupationValidatorRef}
                      errorMessage={t("create_expense.select_occupation")}
                    >
                      <React.Fragment>
                        <label className="input-label">
                          {t("create_expense.select_occupation")}
                          <i className="input-required">*</i>
                        </label>
                        <Select
                          ref={creatorOccupationRef}
                          options={creatorOccupationOptions}
                          onChoose={creatorOccupationtChooseHandler}
                          defaultText={t("create_expense.select_occupation")}
                          disabled={!!props.editId}
                        />
                      </React.Fragment>
                    </Validator>
                  ) : null}
                </If>
                {withExpense ? (
                  <>
                    <Validator
                      style={{ marginTop: "18px" }}
                      ref={expenseValidator}
                      type={"select"}
                    >
                      <div style={{ marginRight: "-12px" }}>
                        <SelectExpense
                          filterParams={filterParams}
                          readOnly={props.editId ? withExpense : false}
                          onChoose={expenseApplicationOnChooseHandler}
                          defaultValue={editMode ? expenseApplication : null}
                          defaultText={t(
                            "modals.create_advance_report_modal.select_request_for_expense"
                          )}
                        />
                      </div>
                    </Validator>

                    <>
                      <div
                        className={`input-item-row znr-search-row ${
                          editMode ? "d-none" : ""
                        }`}
                        style={{ alignItems: "flex-end" }}
                      >
                        <div className="input-item znr-by-business-trip">
                          <label className="input-label">
                            {t(
                              "modals.create_advance_report_modal.search_by_business_trip_date"
                            )}
                          </label>
                        </div>
                        <div className="input-item znr-by-cities">
                          <label className="input-label">
                            {t(
                              "modals.create_advance_report_modal.search_by_cities"
                            )}
                          </label>
                        </div>
                      </div>
                      <div
                        className={`input-item-row znr-search-row ${
                          editMode ? "d-none" : ""
                        }`}
                      >
                        <div
                          className="input-item"
                          style={{ alignSelf: "baseline" }}
                        >
                          <div
                            className={`input-picker-wrap input-date ${
                              startDate ? "active" : ""
                            }`}
                          >
                            <input
                              value={
                                startDate
                                  ? formatter(
                                      "D MMMM",
                                      i18n.language,
                                      startDate
                                    )
                                  : ""
                              }
                              onClick={onClickToCalendar}
                              className="input datepicker pointer"
                              type="text"
                              id="datepicker"
                              placeholder={t("filters.one_way")}
                              readOnly={true}
                            />
                            <SvgIcon
                              className={"icon icon-calendar"}
                              href={"#svg_icon_calendar"}
                              onClick={onClickToCalendar}
                            />
                          </div>
                          <DatePickerCalendar
                            startDate={startDate}
                            isShown={isOpenToCalendar}
                            onSelected={onSelectedToDate}
                            isCleansed={false}
                            isMultiChoice={false}
                            disableAfterDate={endDate}
                            onClose={() => setOpenToCalendar(false)}
                          />
                        </div>
                        <div
                          className="input-item"
                          style={{ alignSelf: "baseline" }}
                        >
                          <div
                            className={`input-picker-wrap input-date ${
                              endDate ? "active" : ""
                            }`}
                          >
                            <input
                              value={
                                endDate
                                  ? formatter("D MMMM", i18n.language, endDate)
                                  : ""
                              }
                              onClick={onClickBackCalendar}
                              className="input datepicker pointer"
                              type="text"
                              id="datepicker_2"
                              placeholder={t("filters.back")}
                              readOnly={true}
                            />
                            <SvgIcon
                              onClick={onClickBackCalendar}
                              className={"icon icon-calendar"}
                              href={"#svg_icon_calendar"}
                            />
                          </div>
                          <DatePickerCalendar
                            startDate={endDate}
                            isShown={isOpenBackCalendar}
                            onSelected={onSelectedBackDate}
                            isCleansed={false}
                            isMultiChoice={false}
                            disableBeforeDate={startDate}
                            onClose={() => setOpenBackCalendar(false)}
                          />
                        </div>
                        <div className="input-item">
                          <label className="input-label znr-by-cities-mob">
                            {t(
                              "modals.create_advance_report_modal.search_by_cities"
                            )}
                          </label>
                          <CityAutocomplete
                            ref={cityAutocompleteRef}
                            onChoose={onCityChooseHandler}
                            placeholder={t("filters.city")}
                          />
                          <InputChoose
                            options={options}
                            onDelete={onCityDeleteHandler}
                          />
                        </div>
                      </div>
                      <div
                        className={`input-item-row znr-search-row ${
                          editMode ? "d-none" : ""
                        }`}
                      >
                        <div className="input-item znr-by-employee">
                          <label className="input-label">
                            {t(
                              "modals.create_advance_report_modal.search_by_employee"
                            )}
                          </label>
                          <div className="input-search">
                            <EmployeeAutocomplete
                              ref={employeeAutocompleteRef}
                              onChoose={employeeChooseHandler}
                              placeholder={t("filters.enter_name")}
                              tabIndex={isTabIndexDisabled ? -1 : 0}
                            />
                          </div>
                        </div>
                        <Button
                          onClick={searchZNRHandler}
                          buttonColor="gray"
                          buttonText={t(
                            "modals.create_advance_report_modal.search"
                          )}
                          big
                        />
                      </div>
                    </>
                  </>
                ) : null}
                {editMode && !!advanceReportDetail.expenseApplication.id ? (
                  <div className="box-modal-form-block disabled">
                    <Validator ref={expenseValidator} type={"select"}>
                      <SelectExpense
                        readOnly={true}
                        defaultValue={editMode ? expenseApplication : null}
                        defaultText={t(
                          "modals.create_advance_report_modal.select_request_for_expense"
                        )}
                      />
                    </Validator>
                  </div>
                ) : null}
              </div>
              {(editMode ||
                isSelectOccupation ||
                creatorOccupationOptions.length < 1) &&
              !withExpense ? (
                <Fragment>
                  <div className={`box-modal-form-block`}>
                    <div className="title">
                      {t("modals.create_advance_report_modal.application_type")}
                    </div>
                    <div className="input-item">
                      <div className="box-modal-checkbox-wrap">
                        {expenseTypes.map((expType, index) => {
                          return (
                            <RadioButton
                              key={expType.id}
                              name="expenseTypeRadio"
                              id={expType.id}
                              disabled={
                                editMode &&
                                !editPermissions.includes(
                                  ADVANCE_APPLICATION_PROPETIES.APPLICATION_TYPE
                                )
                              }
                              label={expType.name[i18n.language as "ru" | "en"]}
                              checked={expType.id === expenseType}
                              onClick={typeRadioClickHandler}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="box-modal-form-block">
                    <div className="title">
                      {t(
                        "modals.create_advance_report_modal.application_details"
                      )}
                    </div>
                    {expenseTypeCode === "Travel" &&
                    (createPermissions.includes(
                      ADVANCE_APPLICATION_PROPETIES.BUSINESS_TRIP_OPTION
                    ) ||
                      editPermissions.includes(
                        ADVANCE_APPLICATION_PROPETIES.BUSINESS_TRIP_OPTION
                      )) ? (
                      <div
                        className={`box-modal-form-block ${generateClassName(
                          ADVANCE_APPLICATION_PROPETIES.BUSINESS_TRIP_OPTION
                        )}`}
                      >
                        <Road
                          defaultOptions={roads}
                          onChange={changeRoads}
                          onChangeRoadMode={changeRoadMode}
                          multiRoadMode={multiRoadMode}
                          isAdvance={true}
                          tabIndex={isTabIndexDisabled ? -1 : 0}
                        />
                      </div>
                    ) : null}
                    <div
                      className="box-modal-form-block"
                      style={{ marginBottom: 0 }}
                    >
                      <If condition={!editMode}>
                        {assistanceOptions.length > 0 &&
                        !isAssistantManagerOccupation() &&
                        !isCoordinatorOccupation() ? (
                          <Validator
                            type={"select"}
                            ref={assistanceValidatorRef}
                          >
                            <React.Fragment>
                              <label className="input-label">
                                {t("create_expense.assignee_employee")}
                                <i className="input-required">*</i>
                              </label>
                              <Select
                                ref={assistanceRef}
                                defaultValue={assistance}
                                options={assistanceOptions}
                                onChoose={assistanceChooseHandler}
                                defaultText={t(
                                  "create_expense.placeholder.select_employee"
                                )}
                                disabled={!!props.editId}
                                style={{ marginRight: "-12px" }}
                              />
                            </React.Fragment>
                          </Validator>
                        ) : null}
                        {isCoordinatorOccupation() ? (
                          <Validator
                            type={"text"}
                            ref={responsibleValidatorRef}
                            isRoad={isIE ? true : false}
                          >
                            <React.Fragment>
                              <label className="input-label">
                                {t("create_expense.assignee_employee")}
                                <i className="input-required">*</i>
                              </label>
                              <div
                                className="input-item"
                                style={{ marginBottom: "0px" }}
                              >
                                <div
                                  className="input-search"
                                  style={{ marginRight: "-12px" }}
                                >
                                  <EmployeeAutocomplete
                                    placeholder={t(
                                      "create_expense.placeholder.enter_employee"
                                    )}
                                    onChoose={responsibleChooseHandler}
                                    defaultText={
                                      responsible.text != ""
                                        ? responsible.text
                                        : undefined
                                    }
                                    readOnly={employeeAutocompleteReadOnly}
                                    tabIndex={isTabIndexDisabled ? -1 : 0}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          </Validator>
                        ) : null}
                        {responsibleOccupationOptions.length > 0 ? (
                          <Validator
                            type={"select"}
                            ref={responsibleOccupationRef}
                          >
                            <React.Fragment>
                              <label className="input-label">
                                {t(
                                  "create_expense.assignee_employee_occupation"
                                )}
                                <i className="input-required">*</i>
                              </label>
                              <Select
                                //ref={assistantRef}
                                defaultValue={responsibleOccupation}
                                options={responsibleOccupationOptions}
                                onChoose={responsibleOccupationChooseHandler}
                                defaultText={t(
                                  "create_expense.placeholder.select_employee"
                                )}
                                disabled={!!props.editId}
                                style={{ marginRight: "-12px" }}
                              />
                            </React.Fragment>
                          </Validator>
                        ) : null}
                      </If>
                      <Validator
                        ref={businessGoalValidator}
                        type={"select"}
                        errorMessage={t("create_expense.select_business_goal")}
                        className={`${generateClassName(
                          ADVANCE_APPLICATION_PROPETIES.BUSINESS_TARGET
                        )}`}
                        style={{ display: hideBusinessGoal ? "none" : "block" }}
                      >
                        <div>
                          <label className="input-label">
                            {t(
                              "modals.create_advance_report_modal.business_goal"
                            )}
                            <i className="input-required">*</i>
                          </label>
                          <Select
                            ref={businessGoalRef}
                            options={businessGoalOptions.map(
                              (businessGoalOption) => {
                                return {
                                  value: businessGoalOption.id,
                                  text: businessGoalOption.name[
                                    i18n.language as "ru"
                                  ],
                                };
                              }
                            )}
                            defaultText={t(
                              "modals.create_advance_report_modal.enter_business_goal"
                            )}
                            onChoose={businessGoalChooseHandler}
                            defaultValue={
                              editMode || businessGoalOnlyOne
                                ? businessGoal
                                : null
                            }
                            style={{ marginRight: "-12px" }}
                          />
                        </div>
                      </Validator>
                      <div
                        className={`box-modal-form-block ${generateClassName(
                          ADVANCE_APPLICATION_PROPETIES.DESCRIPTION
                        )}`}
                      >
                        <div className={`input-item`}>
                          <label className="input-label">
                            {t(
                              "modals.create_advance_report_modal.description"
                            )}
                          </label>
                          <input
                            className="input"
                            type="text"
                            ref={descriptionRef}
                            value={description}
                            maxLength={400}
                            onChange={changeDescription}
                            placeholder={t(
                              "modals.create_advance_report_modal.enter_description"
                            )}
                            tabIndex={isTabIndexDisabled ? -1 : 0}
                          />
                        </div>
                      </div>
                    </div>
                    <CustomProperty
                      availableProperties={filterCustomProperties}
                      dataExpense={dataRef.current}
                      type={"AdvanceReportApplication"}
                      id={props.editId}
                      isEdit={!!props.editId}
                      isAO={true}
                      ref={customPropertyRef}
                      permissions={
                        !!props.editId ? editPermissions : createPermissions
                      }
                      occupationsId={assigneeDetail.occupationId}
                      companyId={
                        props.editId
                          ? advanceReportDetail && advanceReportDetail.company
                            ? advanceReportDetail.company.id
                            : undefined
                          : assigneeDetail.companyId
                      }
                      tabIndex={isTabIndexDisabled ? -1 : 0}
                    />
                  </div>
                </Fragment>
              ) : null}
            </div>
          </div>
          <div className="box-modal-footer">
            <Button
              onClick={closeHandler}
              buttonWidth="153px"
              buttonText={t("modals.create_advance_report_modal.cancel")}
            />
            <Button
              onClick={saveHandler}
              buttonWidth="153px"
              buttonColor="green"
              buttonText={
                props.hasNext
                  ? t("request_detail.request_button_controls.next")
                  : t("modals.create_advance_report_modal.complete")
              }
            />
          </div>
        </div>
      </ModalContainer>
      <CreateOrderModal
        id={get(props, "editId", 1)}
        isOpen={isShowDailyRenew}
        isAdvance={true}
        onClose={closeDailyRenew}
        dailyRenew={true}
      />
    </Fragment>
  );
};

export default withRouter(CreateAOModal);
