/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserCertificates,
  getSystemInfo,
  isValidSystemSetup,
  SystemInfo,
  createHash,
  createDetachedSignature,
} from "crypto-pro";
import SignNotice from "./SignNotice";
import { Certificates, SignDocumentProps } from "./types";
import DocumentsModal from "./DocumentsModal";
import { loaderLock, loaderUnlock } from "store/common/actions";

import agreementService from "services/entity/agreement.service";
import {
  getAdvanceReportApplication,
  getApiRootCertificates,
  getClientCaCertificates,
  getClientRootCertificates,
  getCommonCompaniesCatalog,
  getCommonUserDetail,
} from "store/selectors";
import {
  getApprovalsByObject,
  checkSigningPowerOfAttorneyRequired,
  getBlobFileContentById,
  postBlobFile,
  signingAdvanceReportApplications,
  updateApprovalsItems,
} from "services/ApiService";
import { IAO } from "infrastructure/interfaces";
import PowerOfAttorneyRequired from "app/pages/powers-of-attorney/dialogs/PowerOfAttorneyRequired";
import {
  checkRootCertificate,
  getAllCertificates,
  getCertificateWithSKIAndAKI,
} from "../util/CertificatesHelper";
import { updateDetailedAO } from "store/report/actions";
import { showErrors } from "store/exception/actions";

const SignDocuments: React.FC<SignDocumentProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getCommonUserDetail);
  const company = useSelector(getCommonCompaniesCatalog);

  const { approvalSubmit } = props;

  const [isOpenDocumentsModal, setOpenDocumentsModal] = useState(false);
  const [isOpenSignsModal, setOpenSignsModal] = useState(false);
  const [certificates, setCertificates] = useState([] as Certificates[]);
  const [isOpenNotice, setOpenNotice] = useState(false);
  const [systemInfo, setSystemInfo] = useState(
    {} as SystemInfo & { isValidSystemSetup: boolean }
  );
  const [systemInfoError, setSystemInfoError] = useState(null);
  const [noticeType, setNoticeType] = useState("");
  const [noticeText, setNoticeText] = useState("");
  const [signDocumentsData, setSignDocumentsData] = useState([] as any);
  const [approvalDocumentsData, setApprovalSignDocumentsData] = useState(
    [] as any
  );
  const [
    isSignatureRequiredOnCurrentTier,
    setIsSignatureRequiredOnCurrentTier,
  ] = useState(true);

  const [thumbprint, setThumbprint] = useState("");
  const [utcOffset, setUtcOffset] = useState(0);
  const [powerOfAttorneyId, setPowerOfAttorneyId] = useState(0);
  const [isPowerOfAttorneyRequired, setIsPowerOfAttorneyRequired] = useState({
    modal: false,
    getDocumentData: [],
    getSignItemById: 0,
  });
  const [powerOfAttorneyRequired, setPowerOfAttorneyRequired] = useState(false);
  const [objectLogicalNames, setObjectLogicalNames] = useState<Array<string>>(
    []
  );
  const [allDoc, setAllDoc] = useState(false);

  const advanceReportApplication: IAO = useSelector(
    getAdvanceReportApplication
  );
  const apiRootCertificates = useSelector(getApiRootCertificates);
  const clientRootCertificates = useSelector(getClientRootCertificates);
  const clientCaCertificates = useSelector(getClientCaCertificates);

  const companyId = advanceReportApplication.company.id;
  const userOccupationId = user.occupations.filter(
    (occupation) => occupation.company.id == companyId
  )[0].occupationId;

  useEffect(() => {
    setUtcOffset(moment().utcOffset());
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setSystemInfo({
          ...(await getSystemInfo()),
          isValidSystemSetup: await isValidSystemSetup(),
        });
      } catch (error: any) {
        setSystemInfoError(error.message);
      }
    })();
  }, []);

  const getCurrentTier = async () => {
    let currentTier = await agreementService.getCurrentTier();
    if (currentTier && currentTier.approvalTierDocuments) {
      setIsSignatureRequiredOnCurrentTier(
        currentTier.approvalTierDocuments[0].isSignatureRequired
      );
    }
  };

  const getAllUserDocuments = async () => {
    dispatch(loaderLock());
    let list = await agreementService.getList();
    if (list) {
      setApprovalSignDocumentsData(list);
      const currentApprovalTierId = list.currentApprovalTierId;
      const userDocuments = list.approvalTiers.map((document) => {
        if (document.id === currentApprovalTierId) {
          if (
            document.employees.filter((employe) => employe.id === user.id)
              .length > 0
          ) {
            return document;
          }
        }
      });
      const filterUserDocuments = userDocuments.filter((document) => {
        return document !== undefined;
      });
      if (filterUserDocuments && filterUserDocuments.length > 0) {
        const approvalTierDocuments =
          filterUserDocuments[0].approvalTierDocuments;
        if (approvalTierDocuments && approvalTierDocuments.length > 0) {
          const objectLogicalName = approvalTierDocuments[0].objectLogicalName;
          if (objectLogicalName) {
            setObjectLogicalNames([objectLogicalName]);
          }
        }
      }
      setSignDocumentsData(
        filterUserDocuments && filterUserDocuments.length > 0
          ? filterUserDocuments[0]
          : []
      );
    }
    dispatch(loaderUnlock());
  };

  const signSes = async (documentData: any[], signItemById: any[]) => {
    const payload = {
      signatureType: documentData[0] && documentData[0].signatureTypes[0],
      occupationId: userOccupationId,
      signingBlobFileId: documentData[0].signingFileId,
      signedAt: new Date().toISOString(),
      approvalId: approvalDocumentsData.id,
      itemId: signItemById[0].approvalSheetItemId,
      approvalDocumentId: documentData[0].id,
    };
    return await signingAdvanceReportApplications(
      documentData[0].objectId,
      `${documentData[0].objectLogicalName}s`,
      utcOffset,
      payload
    );
  };

  const createSignBlobFile = async (documentData: any[]) => {
    const uploadSignatureFileName = `${
      documentData[0].objectLogicalName === "AdvanceReportApplication"
        ? "AdvanceReport"
        : documentData[0].objectLogicalName
    }-${documentData[0].objectId}-${thumbprint}.pdf.sgn`;
    const hash = await getHash(documentData);
    const signature = await createDetachedSignature(thumbprint, hash);
    const blob = new Blob([signature]);
    const fileToUpload = new File([blob], uploadSignatureFileName, {
      type: "application/octet-stream",
    });
    return fileToUpload;
  };

  const signAqes = async (documentData: any[], signItemById: any[]) => {
    const fileToUpload = await createSignBlobFile(documentData);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("ObjectId.LogicalName", documentData[0].objectLogicalName);
    formData.append("ObjectId.Id", documentData[0].objectId);
    formData.append("Type", "Signature");
    formData.append("Content", fileToUpload);
    const selectedSertificate = certificates.filter(
      (sert) => sert.thumbprint === thumbprint
    );
    const signatureBlobFile = await postBlobFile(formData, config);

    if (signatureBlobFile) {
      const payload = {
        signatureType: selectedSertificate[0].type,
        occupationId: userOccupationId,
        signingBlobFileId: documentData[0].signingFileId,
        signedAt: new Date().toISOString(),
        approvalId: approvalDocumentsData.id,
        itemId: signItemById[0].approvalSheetItemId,
        approvalDocumentId: documentData[0].id,
        signatureBlobFileId: signatureBlobFile.data.id,
        certificate: {
          serialNumber: selectedSertificate[0].thumbprint,
          issuerName: selectedSertificate[0].issuerName,
          subjectName: selectedSertificate[0].subjectName,
          validFromDate: selectedSertificate[0].validFrom,
          validToDate: selectedSertificate[0].validTo,
          thumbprint: selectedSertificate[0].thumbprint,
        },
        powerOfAttorneyId: powerOfAttorneyId ? powerOfAttorneyId : null,
      };
      return await signingAdvanceReportApplications(
        documentData[0].objectId,
        `${documentData[0].objectLogicalName}s`,
        utcOffset,
        payload
      );
    }
  };

  function readFile(messageFile: Blob) {
    return new Promise(function (resolve, reject) {
      const fileReader = new FileReader();
      fileReader.onload = function () {
        resolve(this.result);
      };
      fileReader.readAsArrayBuffer(messageFile);
    });
  }

  const getHash = async (documentData: any) => {
    const blobFileContent = await getBlobFileContentById(
      documentData[0].signingFileId,
      { responseType: "blob" }
    );
    const blob = new Blob([blobFileContent.data]);
    const file = new File([blob], "file.pdf", {
      type: "application/pdf",
    });
    const readFilePromise = await readFile(file);
    if (readFilePromise) {
      try {
        const hash = await createHash(readFilePromise);
        return hash;
      } catch (error) {
        console.log(error);
      }
    }
  };
  //Присваиваем каждому сертификату тип: УКЭП/УНЭП
  const getType = async (certificates: any) => {
    // массив всех промежуточных и корневых сертификатов
    let allInternalCertificates = [];
    // типированные сертификаты
    let typedCertificate = [];
    // получаем промежуточные
    const caCertificates = await getAllCertificates(clientCaCertificates, "CA");
    // получаем корневые
    const rootCertificates = await getAllCertificates(
      clientRootCertificates,
      "ROOT"
    );
    // собираем в один массив
    allInternalCertificates = caCertificates.concat(rootCertificates).flat();

    for (let i = 0; i < certificates.length; i++) {
      // добавляем клиентским сертификатам новые поля: SKI id и AKI id (по этим id мы находим корневые и промежуточные сертификаты)
      const clientCertificateWithSKIAndAKI = await getCertificateWithSKIAndAKI(
        certificates[i]
      );
      if (clientCertificateWithSKIAndAKI.authorityKeyIdentifier) {
        // все внутренние сертификаты и клиентские соотносим по их SKI id и AKI id (подробнее в UEXP-1665)
        // все внутренние сертификаты и клиентские соотносим по их SKI id и AKI id (подробнее в UEXP-1665)
        const isAQES = checkRootCertificate(
          allInternalCertificates,
          clientCertificateWithSKIAndAKI.authorityKeyIdentifier,
          apiRootCertificates,
          clientCertificateWithSKIAndAKI.subjectName
        );
        certificates[i].type = isAQES ? "AQES" : "AES";
      } else {
        certificates[i].type = "AES";
      }

      typedCertificate.push(certificates[i]);
    }
    return typedCertificate;
  };

  const getSign = () => {
    dispatch(loaderLock());
    (async () => {
      try {
        const certificates: Certificates[] = await getUserCertificates();
        const typedCertificate = await getType(certificates);
        let sortedCertificates = typedCertificate.filter(
          (item) =>
            signDocumentsData.approvalTierDocuments[0] &&
            signDocumentsData.approvalTierDocuments[0].signatureTypes.includes(
              item.type
            )
        );
        setCertificates(sortedCertificates);
        dispatch(loaderUnlock());
        if (!sortedCertificates.length) {
          setNoticeType("no_proper_certificate");
          setOpenNotice(true);
        }
      } catch (error) {
        dispatch(loaderUnlock());
        console.log("getSign error:", error);
      }
    })();
    if (systemInfoError) {
      setNoticeType("notice_browser_plugin");
      setOpenNotice(true);
    }
  };
  const checkSes = signDocumentsData.approvalTierDocuments
    ? signDocumentsData.approvalTierDocuments.find(
        (item: any) => item && item.signatureTypes.includes("SES")
      )
    : false;

  const signFinal = async (getDocumentData: any[], getSignItemById: any) => {
    dispatch(loaderLock());
    await signAqes(getDocumentData, getSignItemById).then((e) => {
      if (e && e.status === 201) {
        setNoticeType("success");
        setOpenNotice(true);
        setOpenSignsModal(false);
      } else {
        if (e && e.request.response) {
          setNoticeText(JSON.parse(e.request.response).Messages[0]);
        }
        setNoticeType("alert");
        setOpenNotice(true);
      }
    });
    dispatch(loaderUnlock());
    await getAllUserDocuments();
    setThumbprint("");
  };

  const signDocument = async (id: number) => {
    const getDocumentData = signDocumentsData.approvalTierDocuments.filter(
      (doc: { objectId: number }) => doc.objectId === id
    );

    const getSignItemById = signDocumentsData.employees.filter(
      (item: { id: number }) => item.id === user.id
    );

    if (
      getDocumentData[0] &&
      getDocumentData[0].signatureTypes.includes("SES")
    ) {
      dispatch(loaderLock());
      await signSes(getDocumentData, getSignItemById).then((e) => {
        if (e.status === 201) {
          setNoticeType("success");
          setOpenNotice(true);
        } else {
          if (e.request.response) {
            setNoticeText(JSON.parse(e.request.response).Messages);
          }
          setNoticeType("alert");
          setOpenNotice(true);
        }
      });
      dispatch(loaderUnlock());
      await getAllUserDocuments();
    }

    if (
      getDocumentData[0] &&
      (getDocumentData[0].signatureTypes.includes("AQES") ||
        getDocumentData[0].signatureTypes.includes("AES"))
    ) {

      if (!!checkSes) {
        if (!thumbprint) {
          getSign();
        }
      }
      if (systemInfoError) {
        setNoticeType("notice_browser_plugin");
        setOpenNotice(true);
        return;
      }
      if (!thumbprint) {
        getSign();
      }
      if (thumbprint) {
        if (powerOfAttorneyRequired) {
          if (allDoc) {
            setIsPowerOfAttorneyRequired({
              modal: true,
              getDocumentData: signDocumentsData.approvalTierDocuments,
              getSignItemById,
            });
          } else {
            setIsPowerOfAttorneyRequired({
              modal: true,
              getDocumentData,
              getSignItemById,
            });
          }
        } else {
          signFinal(getDocumentData, getSignItemById);
        }
      }
      getAllUserDocuments();
    }
  };
  const [state, setState] = useState(0);
  const completeSign = async () => {
    await approvalSubmit();
    setState(state + 1);
  };

  const signAllDocuments = (userDocuments: any) => {
    if (!thumbprint && !checkSes) {
      getSign();
    }
    let documentsId = [];
    if (advanceReportApplication) {
      documentsId.push(advanceReportApplication.additionalDocuments);
      if (advanceReportApplication.additionalDocuments.length) {
        advanceReportApplication.additionalDocuments.map((document) => 
          documentsId.push(document.id)
        );
      }
    }
    // Проблема в том что при подписании укэп, подписывается первым делом акт, хотя нажимаю  АО, путаются id
    // при подписании всех документов подписываем только те документы, по которым еще нет подписей
    const userDocumentId = userDocuments.map(
      (doc: { objectId: any }) => doc.objectId
    );
    const filterUserDocumentId = [...new Set(userDocumentId)];
    documentsId.map((document) => {
      if (!filterUserDocumentId.filter((doc) => doc === document).length) {
        signDocument(document);
      }
    });
  };

  useEffect(() => {
    getAllUserDocuments();
    getCurrentTier();
  }, [isOpenDocumentsModal, isOpenNotice, state]);

  useEffect(() => {
    if (
      !thumbprint &&
      !checkSes &&
      isOpenSignsModal &&
      clientCaCertificates &&
      clientRootCertificates
    ) {
      getSign();
    }
  }, []);

  const approveDocuments = async () => {
    const approvalSheet = await getApprovalsByObject({
      params: {
        id: advanceReportApplication.id,
        logicalName: "AdvanceReportApplication",
      },
    });

    const aprrovalSheetItemId = approvalSheet.data.id;
    const approvalSheetId = approvalSheet.data.approvalSheetId;

    let response = await updateApprovalsItems(
      approvalSheetId,
      aprrovalSheetItemId,
      { resolution: "Approved", comment: "" }
    );
    if (response.headers.success) {
      dispatch(updateDetailedAO(advanceReportApplication.id));
      completeSign();
    } else {
      dispatch(
        showErrors({
          code: "approvals_action",
          message: "Не удалось согласовать",
        })
      );
    }
  };

  const toggleDocumentModal = () => {
    setOpenDocumentsModal(!isOpenDocumentsModal);
  };

  const toggleSignsModal = () => {
    setOpenSignsModal(!isOpenSignsModal);
  };

  const toggleNoticeModal = () => {
    setOpenNotice(!isOpenNotice);
  };

  const checkIsPowerOfAttorneyRequired = async (
    isLegalEntity: boolean,
    sertificateType: string
  ) => {
    const data = {
      certificateType: sertificateType,
      isLegalEntity: isLegalEntity,
      employeeId: user.id,
    };

    const result = await checkSigningPowerOfAttorneyRequired(
      advanceReportApplication.id,
      data
    );
    if (result.headers.success) {
      setPowerOfAttorneyRequired(result.data.isSigningPowerOfAttorneyRequired);
    }
  };

  const onClick = (thumbprint: string) => {
    const newCertificatesData = certificates.map((certificate) =>
      certificate.thumbprint === thumbprint
        ? { ...certificate, checked: true }
        : { ...certificate, checked: false }
    );
    setCertificates(newCertificatesData as Certificates[]);
    setThumbprint(thumbprint);

    const companyINN = company.filter((el) => el.id === companyId)[0].legal.inn;
    const certificateIsForLegalEntity = certificates
      .filter((sert) => sert.thumbprint === thumbprint)[0]
      .subjectName.includes(companyINN);

    const selectedSertificate = newCertificatesData.filter(
      (sert) => sert.thumbprint === thumbprint
    );

    if (selectedSertificate[0] && selectedSertificate[0].type) {
      checkIsPowerOfAttorneyRequired(
        certificateIsForLegalEntity,
        selectedSertificate[0].type
      );
    }
  };

  return (
    <>
      <div
        className="btn-expense btn_green pointer"
        onClick={
          isSignatureRequiredOnCurrentTier
            ? toggleDocumentModal
            : approveDocuments
        }
      >
        {" "}
        {isSignatureRequiredOnCurrentTier
          ? t("request_detail.request_button_controls.approval_and_sign")
          : t("request_detail.request_button_controls.approval")}
      </div>
      <DocumentsModal
        thumbprint={thumbprint}
        isOpen={isOpenDocumentsModal}
        signDocumentsData={signDocumentsData}
        completeSign={completeSign}
        signAllDocuments={signAllDocuments}
        selectDocument={signDocument}
        onClose={toggleDocumentModal}
        certificates={certificates}
        setOpenSignsModal={setOpenSignsModal}
        onClick={onClick}
        isOpenCertificate={isOpenSignsModal}
        onCloseCertificate={toggleSignsModal}
        setAllDoc={setAllDoc}
        allDoc={allDoc}
        signOneDocument={signDocument}
        setNoticeType={setNoticeType}
        setOpenNotice={setOpenNotice}
      />
      <SignNotice
        text={noticeText}
        noticeType={noticeType}
        isOpen={isOpenNotice}
        onClose={toggleNoticeModal}
      />
      <PowerOfAttorneyRequired
        objectLogicalNames={objectLogicalNames}
        signFinal={signFinal}
        powerOfAttorneyId={powerOfAttorneyId}
        setPowerOfAttorneyId={setPowerOfAttorneyId}
        setIsPowerOfAttorneyRequired={setIsPowerOfAttorneyRequired}
        isPowerOfAttorneyRequired={isPowerOfAttorneyRequired}
      />
    </>
  );
};

export default SignDocuments;
